import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { CssBaseline, Container, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import io from 'socket.io-client';
import * as actions from '../../store/index';
import DrawerExamList from './DrawerExamList/DrawerExamList';
import DrawerAvatar from './DrawerAvatar/DrawerAvatar';
import NavBar from './NavigationBar/navBar';
import Dashboard from './Dashboard/Dashboard';
import ResultView from './ResultView/ResultView';
import Settings from './Dialogs/Settings/Settings';
import Info from './Dialogs/Info/Info';
import CopyRight from './Dashboard/Copyright';
import SendDicoms from './Dialogs/SendDicoms/SendDicoms';
import Report from './Dialogs/Report/Report';
import ConfirmDialog from './Dialogs/Confirm/ConfirmDialog';
import TextInfo from './Dialogs/TextInfo/TextInfo';
import LabelingStats from './Dialogs/LabelingStats/LabelingStats';
import DataExport from './Dialogs/DataExport/DataExport';
import { WS_ENDPOINT } from '../../config';
import ServerLogs from './Dialogs/ServerLogs/ServerLogs';
import ContextMenu from './ContextMenu/ContextMenu';
import DrawerMenu from './DrawerMenu/DrawerMenu';
import DrawerModelList from './DrawerModelList/DrawerModelList';
import TrainingDialog from './Dialogs/TrainingDialog/TrainingDialog';
import FilterDrawer from './FilterDrawer/FilterDrawer';
import Premium from './Dialogs/Premium/Premium';
import { AlertTitle } from '@material-ui/lab';
import VersionChangeLogs from './Dialogs/ChangeLogs/VersionChangeLogs';
import RiskAssessmentForm from './Dialogs/RiskManagement/RiskAssessment/RiskAssessmentForm';
import RiskAssessmentReport from './Dialogs/RiskManagement/RiskAssessment/RiskAssessmentReport';
import UserManagement from './Dialogs/UserManagement/UserManagement';

const useStyles = makeStyles((theme) => ({
  main_layout_container: {
    display: 'flex',
    height: '100vh',
    backgroundColor: theme.palette.complementary.mainDark,
    overflow: 'hidden',
  },

  hide: {
    display: 'none',
  },

  datePickerGridItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  main_container_landscape: {
    overflow: 'hidden',
    flexGrow: 1,
    width: '100vw',
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: theme.palette.complementary.darker,
    height: '93vh',
    top: '4%',
    padding: '10px',
  },

  main_container_responsive: {
    overflow: 'auto',
    flexGrow: 1,
    width: '100vw',
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: theme.palette.complementary.darker,
    height: '95vh',
    top: '3%',
    padding: '8px',
  },

  ribbon: {
    position: 'fixed',
    top: '1%',
    left: '-3%',
    backgroundColor: '#46b0b03b',
    color: '#fff',
    padding: '0.5rem',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    zIndex: theme.zIndex.tooltip,
    transform: 'rotate(323deg)',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
    width: '11%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '2%',
    opacity: '0.5',
  },
}));

function Layout() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();

  // Store state
  const filter = useSelector((state) => state.filter);
  const {
    currentExam,
    currentlyProcessed,
    totalExaminations,
    pagination,
    order,
    orderBy,
  } = useSelector((state) => state.examinations);

  const {
    info,
    snackbar,
    contextMenu,
    notifications,
    activeDialog,
    serverLogs,
    actionToConfirm,
    examinationListDrawer,
    tabsIndex,
    landscape,
  } = useSelector((state) => state.gui);
  const languageSelected = useSelector((state) => state.gui.language) ?? 'en';
  const dates = useSelector((state) => state.dates);

  // Local State
  const [openAvatar, setOpenAvatar] = useState(false);
  const [filterIsActive, setFilterIsActive] = useState(false);
  const [bboxSocket, setBboxSocket] = useState(null);
  const [modelsSocket, setModelsSocket] = useState(null);
  const [aiServerConnected, setAiServerConnected] = useState(true);
  const [showChangelog, setShowChangeLog] = useState(false);

  const isDevURL =
    window.location.hostname === 'dev.b-rayz.ch' ||
    window.location.hostname === 'demo.b-rayz.ch' ||
    process.env?.REACT_APP_SOFTWARE_VERSION?.includes('-dev');

  const releaseDateVersion = process.env.REACT_APP_RELEASE_DATE;
  const last_login = localStorage.getItem('last_login');

  // WebSocket signals
  const onMessageSignal = (msg) => {
    console.log('AI server message:', msg);

    if (msg === 'DICOM files received') {
      dispatch(actions.setPendingReprocessing(true));
    } else dispatch(actions.setPendingReprocessing(false));
  };

  const onUpdateSignal = useCallback(
    (exam_id) => {
      if (exam_id === currentExam) {
        console.log('update!!! ;)');
        dispatch(actions.fetchResults());
      } else {
        console.log('no update.');
      }

      dispatch(actions.fetchTotalExaminations());
      dispatch(actions.setCurrentlyProcessed(null));
      dispatch(actions.setPendingReprocessing(false));
    },
    [currentExam, dispatch]
  );

  const onProcessingSignal = (exam_id) => {
    dispatch(actions.setCurrentlyProcessed(exam_id));
    dispatch(actions.setPendingReprocessing(true));
  };

  // Lifecycle
  useEffect(() => {
    if (
      dates.selectedRange === 'day' &&
      window.location.pathname.includes('details') &&
      tabsIndex !== 2 &&
      currentlyProcessed
    ) {
      dispatch(actions.setCurrentExam(currentlyProcessed));
    }
  }, [totalExaminations, currentlyProcessed]);

  useEffect(() => {
    dispatch(actions.fetchGlobalSettings());

    // WebSocket
    const socket = io(WS_ENDPOINT, {
      withCredentials: true,
      transports: ['websocket'],
    });

    socket.on('connect', () => {
      console.log('Connected to AI server');
    });

    socket.on('disconnect', (reason) => {
      console.log('Connection to AI server lost:', reason);
    });

    socket.on('connect_error', (error) => {
      console.error('AI server connection error:', error);
    });

    socket.on('message', (msg) => {
      onMessageSignal(msg);
    });

    socket.on('snackbar', (data) =>
      dispatch(
        actions.setSnackbar({
          msg: data.msg,
          severity: data.severity,
          duration: data.duration,
        })
      )
    );

    socket.on('processing', (exam_id) => {
      onProcessingSignal(exam_id);
    });

    setBboxSocket(socket);

    dispatch(actions.fetchNotifications(localStorage.getItem('username')));

    return () => {
      if (!bboxSocket) return;
      bboxSocket.off('message');
      bboxSocket.off('update');
      bboxSocket.off('snackbar');
      bboxSocket.off('processing');
      bboxSocket.close();
      setBboxSocket(null);
    };
  }, [dispatch, onUpdateSignal]);

  useEffect(() => {
    if (!bboxSocket) return;
    bboxSocket.on('update', (exam_id) => {
      onUpdateSignal(exam_id);
    });
  }, [bboxSocket, onUpdateSignal]);

  useEffect(() => {
    dispatch(actions.fetchDevices());
    dispatch(actions.fetchOperators());
    return () => {
      dispatch(actions.setDeviceList([]));
      dispatch(actions.setOperatorList([]));
      // dispatch(actions.setSnackbar(null))
    };
  }, [totalExaminations, dispatch]);

  useEffect(() => {
    const isActive =
      Object.keys(filter).filter((key) => key !== 'dates' && key !== 'study_id')
        .length > 0;
    setFilterIsActive(isActive);
    dispatch(actions.fetchTotalExaminations());
  }, [dispatch, filter]);

  useEffect(() => {
    if (!currentExam && (pagination[0] !== 0 || pagination[1] !== 5)) {
      dispatch(actions.setPagination([0, 40]));
    }
  }, [filter.dates, examinationListDrawer]);

  useEffect(() => {
    dispatch(actions.fetchExaminations());
  }, [pagination, order, orderBy, totalExaminations]);

  useEffect(() => {
    const lastLoginDate = last_login ? new Date(last_login) : null;
    const lastReleaseDate = releaseDateVersion
      ? new Date(releaseDateVersion)
      : null;

    if (releaseDateVersion && lastLoginDate) {
      if (lastReleaseDate > lastLoginDate) {
        setShowChangeLog(true);
      } else setShowChangeLog(false);
    }
  }, [last_login, releaseDateVersion]);

  return (
    <>
      <div className={classes.main_layout_container}>
        <CssBaseline />
        <NavBar
          socketModuleActive={!!modelsSocket && modelsSocket.connected}
          activeFilter={filterIsActive}
        />
        <DrawerMenu
          onToggleDark={() => dispatch(actions.toggleDarkState())}
          toggleMode={() => dispatch(actions.toggleDarkState())}
          setActiveDialog={(dialog) =>
            dispatch(actions.setActiveDialog(dialog))
          }
          onExamListOpen={() => {
            dispatch(actions.setExaminationListDrawer(!examinationListDrawer));
            setOpenAvatar(false);
          }}
          onAvatarClick={() => {
            dispatch(actions.setExaminationListDrawer(false));
            setOpenAvatar((prevState) => !prevState);
          }}
          notificationsLength={notifications?.length}
        />

        {examinationListDrawer &&
          window.location.pathname.includes('results') && (
            <DrawerExamList activeFilter={filterIsActive} />
          )}

        {examinationListDrawer &&
          window.location.pathname.includes('training') && <DrawerModelList />}

        <Switch>
          <Route path="/bbox/results/dashboard">
            <Container
              maxWidth={false}
              className={
                landscape
                  ? classes.main_container_landscape
                  : classes.main_container_responsive
              }
              style={{
                borderRadius: examinationListDrawer ? '0' : '10px 0 0 10px',
              }}
            >
              <Dashboard />
              <CopyRight />
            </Container>
          </Route>

          <Route path="/bbox/results/details/:study_id?">
            <ResultView />
          </Route>
        </Switch>

        {/* Show only in Demo or Dev versions */}
        {isDevURL && (
          <div
            className={classes.ribbon}
            onClick={() => setShowChangeLog(!showChangelog)}
          >
            <Typography>DEMO</Typography>
          </div>
        )}
      </div>
      {openAvatar && (
        <DrawerAvatar
          onClose={() => setOpenAvatar(false)}
          open={openAvatar}
          fetchOperators={() => dispatch(actions.fetchOperators())}
          onAvatarClick={() => {
            setOpenAvatar((prevState) => !prevState);
            dispatch(actions.setExaminationListDrawer(false));
          }}
        />
      )}

      {activeDialog === 'filter' && <FilterDrawer />}
      {activeDialog === 'settings' && <Settings />}
      {activeDialog === 'info' && <Info />}
      {activeDialog === 'upload' && (
        <SendDicoms setSnackbar={(obj) => dispatch(actions.setSnackbar(obj))} />
      )}
      {activeDialog === 'report' && <Report open={activeDialog === 'report'} />}
      {activeDialog === 'risk_management_form' && <RiskAssessmentForm />}
      {activeDialog === 'risk_management' && (
        <RiskAssessmentReport open={activeDialog === 'risk_management'} />
      )}
      {activeDialog === 'LabelStat' && (
        <LabelingStats
          open={activeDialog === 'LabelStat'}
          onClose={() => dispatch(actions.setActiveDialog(null))}
        />
      )}
      {activeDialog === 'export' && (
        <DataExport
          open={activeDialog === 'export'}
          onClose={() => dispatch(actions.setActiveDialog(null))}
          setSnackbar={(obj) => dispatch(actions.setSnackbar(obj))}
        />
      )}
      {activeDialog === 'server' && (
        <ServerLogs
          open={activeDialog === 'server'}
          onClose={() => dispatch(actions.setActiveDialog(null))}
          text={serverLogs}
        />
      )}
      {!!actionToConfirm && (
        <ConfirmDialog
          action={actionToConfirm.action}
          text={actionToConfirm.text}
          open={!!actionToConfirm}
          onClose={() => dispatch(actions.setActionToConfirm(null))}
        />
      )}
      {!!info && (
        <TextInfo
          text={info}
          open={!!info}
          onClose={() => dispatch(actions.setInfo(null))}
        />
      )}
      {activeDialog === 'users' && (
        <UserManagement open={activeDialog === 'users'} />
      )}
      {snackbar && (
        <Snackbar
          open={!!snackbar}
          autoHideDuration={snackbar.duration || 3500}
          onClose={() => dispatch(actions.setSnackbar(null))}
          style={{ zIndex: 2500 }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={snackbar.severity}
            style={{ zIndex: 2500 }}
          >
            {snackbar.msg}
          </MuiAlert>
        </Snackbar>
      )}
      {/* {!aiServerConnected && (
        <Snackbar
          open={!(bboxSocket && bboxSocket?.connected)}
          autoHideDuration={3500}
          onClose={() => dispatch(actions.setSnackbar(null))}
          style={{ zIndex: 2500 }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={"error"}
            style={{ zIndex: 2500 }}
          >
            <AlertTitle>{t("Layout.server_down_title")}</AlertTitle>
            {t("Layout.server_down_content")}
          </MuiAlert>
        </Snackbar>
      )} */}

      {!!contextMenu && (
        <ContextMenu
          isOpen={!!contextMenu}
          onClose={() => dispatch(actions.setContextMenu(null))}
          items={contextMenu.items}
          left={contextMenu.left}
          top={contextMenu.top}
        />
      )}
      {activeDialog === 'training' && (
        <TrainingDialog
          open={activeDialog === 'training'}
          onClose={() => dispatch(actions.setActiveDialog(null))}
        />
      )}
      {activeDialog === 'premium_upload' && (
        <Premium
          open={activeDialog === 'premium_upload'}
          onClose={() => dispatch(actions.setActiveDialog(null))}
          section={'upload'}
        />
      )}
      {activeDialog === 'premium_export' && (
        <Premium
          open={activeDialog === 'premium_export'}
          onClose={() => dispatch(actions.setActiveDialog(null))}
          section={'export'}
        />
      )}

      {
        <VersionChangeLogs
          open={showChangelog}
          onClose={() => setShowChangeLog(false)}
          title={t('Layout.release_notes')}
          lang={languageSelected}
        />
      }
    </>
  );
}

export default Layout;
