import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Zoom,
  Grid,
  Typography,
  Tooltip,
  Icon,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PatientSummaryTooltip from '../Summary/Patientdetails/PatientSummaryTooltip';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
  pat_name_title: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: theme.fonts.responsiveBig,
  },

  pat_name_title_hovered: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: theme.fonts.responsiveBig,
    color: theme.palette.primary.main,
    textShadow: 'rgb(111 111 111)  1px 1px 7px',
  },

  pat_name_title_container: {
    maxWidth: '95%',
  },

  deleteTooltip: {
    fontSize: theme.fonts.responsiveMedium,
  },

  subheader: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.texts.secondary,
    fontSize: '0.5em',
  },
  arrow: {
    color: theme.palette.texts.secondary,
  },

  arrow_light: {
    color: theme.palette.texts.helper,
  },

  avatar: {
    color: theme.palette.secondary.light,
    background: theme.palette.primary.main,
    cursor: 'pointer',
  },
  patient_info_icon: {
    height: '20px',
    width: 'auto',
    marginLeft: '6px',
  },

  next_icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '28px',
    width: '28px',
  },
}));

export default function ResultDialogTitle(props) {
  const classes = useStyles();
  const results = useSelector((state) => state.results);
  const { t } = useTranslation();
  const { totalExaminations } = useSelector((state) => state.examinations);
  const { zoomedProjection } = useSelector((state) => state.gui);

  const [patientInfoHover, setPatientInfoHover] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 4px',
        maxWidth: '100%',
      }}
    >
      <Tooltip
        title={props.titlePrevious}
        arrow
        interactive
        placement="top"
        TransitionComponent={Zoom}
      >
        <span>
          <IconButton
            onClick={props.onClickBackward}
            size="small"
            className={classes.next_icon}
            disabled={totalExaminations <= 1}
          >
            <NavigateBeforeIcon
              className={zoomedProjection ? classes.arrow_light : classes.arrow}
              fontSize="large"
            />
          </IconButton>
        </span>
      </Tooltip>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        {results.pat_name && (
          <Tooltip
            arrow
            placement="right-start"
            title={
              <div
                style={{
                  padding: '3%',
                  minWidth: '300px',
                  borderRadius: '5px',
                }}
              >
                <PatientSummaryTooltip results={results} />
              </div>
            }
          >
            <div
              onMouseEnter={() => setPatientInfoHover(true)}
              onMouseLeave={() => setPatientInfoHover(false)}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'help',
              }}
            >
              <div className={classes.pat_name_title_container}>
                <Typography
                  className={
                    patientInfoHover
                      ? classes.pat_name_title_hovered
                      : classes.pat_name_title
                  }
                >
                  {results.pat_name.slice(0, 30)}
                </Typography>
              </div>

              <InfoIcon
                fontSize="small"
                className={classes.patient_info_icon}
                color={patientInfoHover ? 'primary' : 'inherit'}
              />
            </div>
          </Tooltip>
        )}
      </div>

      <Tooltip
        title={props.titleNext}
        arrow
        interactive
        placement="top"
        TransitionComponent={Zoom}
      >
        <span>
          <IconButton
            onClick={props.onClickForward}
            size="small"
            className={classes.next_icon}
            disabled={totalExaminations <= 1}
          >
            <NavigateNextIcon
              className={zoomedProjection ? classes.arrow_light : classes.arrow}
              fontSize="large"
            />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
}
