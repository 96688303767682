import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import * as actions from '../../../../../store';
import useResponsive from '../../../../../helpers/hooks/useResponsive';
import { Divider, Tooltip, Typography, Zoom } from '@material-ui/core';
import { qualityEvaluationChip } from '../../../../../helpers/qualityProjection';
import style from './QualityProjections.module.css';
import concept_rcc from '../../../../../resources/concept_images/Concept_RCC.png';
import concept_rcc_light from '../../../../../resources/concept_images/Concept_RCC_light.png';
import concept_rcc_light_top from '../../../../../resources/concept_images/Concept_RCC_light_top.png';
import concept_rcc_light_bottom from '../../../../../resources/concept_images/Concept_RCC_light_bottom.png';
import concept_rcc_top from '../../../../../resources/concept_images/Concept_RCC_top.png';
import concept_rcc_bottom from '../../../../../resources/concept_images/Concept_RCC_bottom.png';
import RedoIcon from '@material-ui/icons/Redo';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

import {
  pnlTooltip,
  parenchymaClass,
  nippleProfileClass,
  nippleOrientationClass,
  pectoMuscClass,
  pnlColor,
  blurAreaChip,
  skinFoldsChip,
  compressionChip,
} from './QualityCommon/QualityCommon';
import { useStyles } from './QualityCommon/QualityProjections.style';
import { BLUR } from '../../../../../config';
import { useAppliedEvaluation } from '../../../../../helpers/hooks/useEvaluation';

const QualityCC = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();

  // Store state
  const { qualityInRevisionMode, qualityInDrawingMode } = useSelector(
    (state) => state.labeling
  );
  const { projections, applied_quality, shown_quality } = useSelector(
    (state) => state.results
  );
  const landscape = useSelector((state) => state.gui.landscape);
  const darkState = useSelector((store) => store.gui.darkState);

  const {
    isXSmallScreen,
    isSmallScreen,
    isMediumScreen,
    isXLargeScreen,
    isXXLargeScreen,
  } = useResponsive();
  const zoomedProjection = useSelector((state) => state.gui.zoomedProjection);
  const guiPresentationDisplay = useSelector(
    (store) => store.gui.presentation_lesions
  );
  const { tabsIndex, examinationListDrawer } = useSelector(
    (state) => state.gui
  );

  // Local state

  const [pnlIsHovered, setPnlIsHovered] = useState(false);
  const [flip, setFlip] = useState(null);
  const [conceptImg, setConceptImg] = useState(null);

  //Variables
  const {
    view_position,
    result,
    onClickNipple,
    onClickPecto,
    onClickLateralParen,
    onClickMedialParen,
    onQualityChipClick,
    onClickCompression,
    onClickBlur,
  } = props;
  const shownQuality = shown_quality || applied_quality;

  const skinFolds = result?.skinFolds;
  const notOnlyBoxes = guiPresentationDisplay !== 'boxes';
  const blurredAreas = result?.blur;
  const PectoNippleLength = result?.PectoNippleLength;

  const smallView =
    examinationListDrawer ||
    zoomedProjection ||
    isXSmallScreen ||
    isSmallScreen ||
    isMediumScreen;
  const bigView = isXLargeScreen || isXXLargeScreen;

  const left = view_position.includes('l');

  // Revision mode
  const qualityInfoMode =
    tabsIndex === 1 ||
    (tabsIndex === 2 && qualityInRevisionMode && !qualityInDrawingMode);

  const qualityInRevison =
    tabsIndex === 2 && qualityInRevisionMode && !qualityInDrawingMode;

  const qualityInDrawingRevision =
    tabsIndex === 2 && qualityInRevisionMode && qualityInDrawingMode;

  // PNL differences
  const calculateBelgianPNLDifference = (PectoNippleLength, mloPNL) => {
    const difference = Math.abs(PectoNippleLength - Number(mloPNL));
    const percentageDifference = (difference / PectoNippleLength) * 100;
    return percentageDifference.toFixed(1);
  };
  const pectoNippleLengthRMLO =
    useAppliedEvaluation('quality', shownQuality, qualityInRevisionMode)?.rmlo
      ?.PectoNippleLength || null;
  const pectoNippleLengthLMLO =
    useAppliedEvaluation('quality', shownQuality, qualityInRevisionMode)?.lmlo
      ?.PectoNippleLength || null;
  const mloPNL =
    view_position === 'rcc'
      ? pectoNippleLengthRMLO?.toFixed(0)
      : pectoNippleLengthLMLO?.toFixed(0);
  const higherPNLValue = Math.max(PectoNippleLength, Number(mloPNL));
  const pnlDifference = `${Math.abs(
    PectoNippleLength - Number(mloPNL)
  )?.toFixed(0)}mm (${calculateBelgianPNLDifference(
    PectoNippleLength,
    mloPNL
  )}%)`;
  const higherCCPNL =
    higherPNLValue?.toFixed(0) === PectoNippleLength?.toFixed(0);

  // Effects
  useEffect(() => {
    if (left) setFlip({ transform: 'scaleX(-1)' });
    else setFlip(null);
  }, [props]);

  useEffect(() => {
    const preloadImages = [
      concept_rcc,
      concept_rcc_light,
      concept_rcc_light_top,
      concept_rcc_light_bottom,
      concept_rcc_top,
      concept_rcc_bottom,
    ];

    preloadImages.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  useEffect(() => {
    let selectedConceptImage = concept_rcc;
    if (
      result?.NippleCentered === 'moderate' ||
      result?.NippleCentered === 'insufficient'
    ) {
      selectedConceptImage =
        result?.NippleAngle > 0
          ? darkState
            ? concept_rcc_top
            : concept_rcc_light_top
          : darkState
          ? concept_rcc_bottom
          : concept_rcc_light_bottom;
    } else if (
      result?.NippleCentered === 'correct' ||
      !result?.NippleCentered
    ) {
      selectedConceptImage = darkState ? concept_rcc : concept_rcc_light;
    }

    setConceptImg(selectedConceptImage);
  }, [result?.NippleAngle, darkState]);

  // PNL
  const handlePnlMouseEnter = () => {
    setPnlIsHovered(true);
    dispatch(actions.setLinesToShow(view_position));
  };

  const handlePnlMouseLeave = () => {
    setPnlIsHovered(false);
    dispatch(actions.hideLinesShown());
  };

  //PARENCHYMA
  const showParencOverlays = (parencResult, parenchymaLocation) => {
    const parenLocation = `${view_position}${parenchymaLocation}`;

    if (parencResult !== 'correct' && parenLocation)
      dispatch(actions.setParenchymaCutsToShow(parenLocation));

    if (parencResult === 'correct')
      dispatch(actions.hideParenchymaCutsShown(parenLocation));

    const newLesionsToShow = {
      [`parenchyma${view_position?.toUpperCase()}`]: true,
    };

    dispatch(actions.setLinesToShow(view_position));

    dispatch(actions.setQualityOverlaysToShow(newLesionsToShow));
  };

  const hideParencOverlays = (proj, parenchymaLocation) => {
    const parenCutsToHide = [`${proj}${parenchymaLocation}`];

    dispatch(actions.hideParenchymaCutsShown(parenCutsToHide));
    dispatch(actions.hideAllLesions());
    if (!pnlIsHovered) dispatch(actions.hideLinesShown());
  };

  //PECTORALIS
  const showPectOverlays = () => {
    const newLesionsToShow = {
      [`pectoralis${view_position?.toUpperCase()}`]: true,
    };

    dispatch(actions.setQualityOverlaysToShow(newLesionsToShow));
  };

  const hidePectOverlays = () => {
    dispatch(actions.hideAllLesions());
  };

  //NIPPLE
  const showNippleProfile = () => {
    dispatch(actions.setNippleProfileToShow(view_position));
  };

  const nippleTiltColor = (nippleClass) => {
    if (nippleClass === 'moderate')
      return theme.palette.projections.nippleTiltUp;
    if (nippleClass === 'insufficient')
      return theme.palette.projections.nippleTiltDownSevere;
  };

  // NIPPLE ORIENTATION
  const nippleOrientationIcon = () => {
    if (!result || Object.keys(result).length === 0) return;

    if (
      result?.NippleCentered === undefined ||
      result?.NippleCentered === 'correct'
    ) {
      return (
        <>
          <Tooltip
            title={
              <Typography
                style={{
                  fontSize: '1.3vh',
                  lineHeight: 1.3,
                }}
              >
                {t('ResultView.nipple_tilt_ok')}
              </Typography>
            }
            arrow
          >
            <TrendingFlatIcon
              style={{
                position: 'absolute',
                left: qualityInfoMode ? '-3%' : '-2%',
                top: bigView ? '47%' : '48%',
                transform: 'rotate(-180deg)',
                color: qualityInDrawingRevision
                  ? 'gray'
                  : theme.palette.projections.nippleStraight,
                cursor: qualityInRevison ? 'pointer' : 'help',
              }}
              className={classes.goodNippleIconHover}
              onClick={() =>
                qualityInRevison && props?.onNippleOrientationClick()
              }
            />
          </Tooltip>
        </>
      );
    } else {
      if (result.NippleAngle > 0) {
        return (
          <>
            <Tooltip
              title={
                <Typography
                  style={{
                    fontSize: '1.3vh',
                    lineHeight: 1.3,
                  }}
                >
                  {`${t(
                    'ResultView.nipple_tilted_up'
                  )}: ${result?.NippleAngle?.toFixed(2)}°`}
                </Typography>
              }
              arrow
            >
              <RedoIcon
                onClick={() =>
                  qualityInRevison && props?.onNippleOrientationClick()
                }
                fontSize="small"
                style={{
                  position: 'absolute',
                  left: '0%',
                  top: '32%',
                  transform: 'rotate(300deg)',
                  color: qualityInDrawingRevision
                    ? 'gray'
                    : nippleTiltColor(result?.NippleCentered),
                  cursor: qualityInRevison ? 'pointer' : 'help',
                }}
                className={
                  result?.NippleCentered === 'moderate'
                    ? classes.tiltedModerateNippleIconHover
                    : classes.tiltedNippleIconHover
                }
              />
            </Tooltip>
          </>
        );
      } else if (result.NippleAngle < 0) {
        return (
          <>
            <Tooltip
              title={
                <Typography
                  style={{
                    fontSize: '1.3vh',
                    lineHeight: 1.3,
                  }}
                >
                  {`${t('ResultView.nipple_tilted_down')}: ${Math.abs(
                    result?.NippleAngle?.toFixed(2)
                  )}°`}
                </Typography>
              }
              arrow
            >
              <RedoIcon
                onClick={() =>
                  qualityInRevison && props?.onNippleOrientationClick()
                }
                fontSize="small"
                style={{
                  position: 'absolute',
                  bottom: '32%',
                  left: '0%',
                  transform: 'rotate(225deg) scaleX(-1)',
                  color: nippleTiltColor(result.NippleCentered),
                  cursor: qualityInRevison ? 'pointer' : 'help',
                }}
                className={
                  result.NippleCentered === 'moderate'
                    ? classes.tiltedModerateNippleIconHover
                    : classes.tiltedNippleIconHover
                }
              />
            </Tooltip>
          </>
        );
      }
    }

    return;
  };

  const showSkinFolds = (proj) => {
    const newLesionsToShow = {
      [`skinFolds${proj.toUpperCase()}`]: true,
    };

    dispatch(actions.setQualityOverlaysToShow(newLesionsToShow));
  };

  const chipsPosition = () => {
    if (isXXLargeScreen)
      return {
        right: left
          ? examinationListDrawer || zoomedProjection
            ? '-5%'
            : '-11%'
          : undefined,
        left: left
          ? undefined
          : examinationListDrawer || zoomedProjection
          ? '-5%'
          : '-11%',
      };
    else
      return {
        right: left ? (smallView ? '-10%' : '-20%') : undefined,
        left: left ? undefined : smallView ? '-10%' : '-20%',
      };
  };

  return (
    <div
      className={style.quality_projection_container}
      style={{ alignItems: flip ? 'flex-end' : 'flex-start' }}
    >
      {result &&
        result &&
        Object.keys(result).length !== 0 &&
        (!qualityInRevisionMode ||
          (qualityInRevisionMode && !qualityInDrawingMode)) &&
        qualityEvaluationChip(
          view_position,
          result?.quality,
          result?.quality_explanation,
          zoomedProjection,
          onQualityChipClick,
          examinationListDrawer || zoomedProjection,
          qualityInfoMode,
          qualityInRevison,
          theme,
          t
        )}

      <div
        style={{
          height: '40px',
          maxWidth: '250px',
          position: 'absolute',
          top: '0',
          right: chipsPosition().right,
          left: chipsPosition().left,
          display: 'flex',
          flexDirection: 'column',
          alignItems: left ? 'flex-end' : 'flex-start',
          zIndex: theme.zIndex.tooltip,
        }}
      >
        {result &&
          result &&
          Object.keys(result).length !== 0 &&
          !qualityInDrawingMode &&
          compressionChip(
            projections?.[props.view_position]?.compression,
            result?.compressionClass,
            onClickCompression,
            qualityInRevison,
            zoomedProjection,
            theme,
            t
          )}

        {result &&
          !qualityInRevisionMode &&
          !qualityInDrawingMode &&
          skinFoldsChip(
            skinFolds,
            showSkinFolds,
            view_position,
            hidePectOverlays,
            notOnlyBoxes,
            zoomedProjection,
            t,
            theme
          )}

        {BLUR === 'yes' &&
          ((result && !qualityInRevisionMode && !qualityInDrawingMode) ||
            (qualityInRevisionMode &&
              !qualityInDrawingMode &&
              result &&
              Object?.keys(result)?.length !== 0)) &&
          blurAreaChip(
            blurredAreas,
            onClickBlur,
            qualityInfoMode,
            qualityInRevison,
            zoomedProjection,
            theme,
            t
          )}
      </div>

      {/* Concept Img */}
      <div className={style.QualityProj} style={flip}>
        <img
          style={{
            maxHeight: landscape ? '35vh' : 'calc(33vh - 170px)',
            filter: darkState ? '' : 'contrast(0.1)',
          }}
          src={conceptImg || concept_rcc}
          alt="not found"
        />

        {/* Nipple */}
        <>
          {result && !qualityInDrawingMode && nippleOrientationIcon()}
          {result?.Nipple && (
            <Tooltip
              title={
                <Typography
                  style={{
                    fontSize: '1.3vh',
                    lineHeight: 1.3,
                  }}
                >
                  {nippleProfileClass(result?.Nipple, t)?.text}
                </Typography>
              }
              arrow
              interactive
              placement="bottom"
              TransitionComponent={Zoom}
              onOpen={() => qualityInfoMode && showNippleProfile()}
              onClose={() =>
                qualityInfoMode && dispatch(actions.hideLinesShown())
              }
            >
              <button
                style={{
                  cursor: qualityInRevison ? 'pointer' : 'help',
                  top: nippleOrientationClass(
                    result?.NippleCentered,
                    result?.NippleAngle
                  )?.pnlY,
                }}
                className={
                  qualityInDrawingRevision
                    ? [classes.nippleCCBase, classes.neutral]
                    : `${classes.nippleCCBase} ${
                        classes[
                          nippleProfileClass(result?.Nipple, t)?.classColor
                        ]
                      }`
                }
                onClick={() => {
                  if (onClickNipple && result !== null && qualityInRevison)
                    onClickNipple();
                }}
              />
            </Tooltip>
          )}
        </>

        {/* Pecto Muscle */}
        <Tooltip
          title={
            <Typography
              style={{
                lineHeight: 1.3,
                fontSize: '1.3vh',
              }}
            >
              {pectoMuscClass(result?.PectoralisCC, t)?.text}
            </Typography>
          }
          arrow
          interactive
          onOpen={() => qualityInfoMode && showPectOverlays()}
          onClose={() => qualityInfoMode && hidePectOverlays()}
          placement={left ? 'right-start' : 'left-start'}
          TransitionComponent={Zoom}
        >
          <button
            style={{ cursor: qualityInRevison ? 'pointer' : 'help' }}
            className={
              qualityInDrawingRevision
                ? [classes.pectMuscleBase, classes.neutral]
                : `${classes.pectMuscleBase} ${
                    classes[pectoMuscClass(result?.PectoralisCC, t)?.classColor]
                  }`
            }
            id={style.pecto_cc}
            onClick={() => {
              if (onClickPecto && result !== null && qualityInRevison)
                onClickPecto();
            }}
          ></button>
        </Tooltip>

        {/* Parenchyma Lateral */}
        <Tooltip
          onOpen={() =>
            qualityInfoMode &&
            showParencOverlays(result?.ParenchymaCutsLateral, 'Lateral')
          }
          onClose={() =>
            qualityInfoMode && hideParencOverlays(view_position, 'Lateral')
          }
          title={
            <Typography style={{ fontSize: '1.3vh', lineHeight: 1.3 }}>
              {t('ResultView.lateral')}{' '}
              {parenchymaClass(result?.ParenchymaCutsLateral, t).text}
            </Typography>
          }
          arrow
          interactive
          placement={left ? 'right-start' : 'left-start'}
          TransitionComponent={Zoom}
        >
          <button
            style={{ cursor: qualityInRevison ? 'pointer' : 'help' }}
            className={
              qualityInDrawingRevision
                ? [classes.parenLateralCCBase, classes.neutral]
                : `${classes.parenLateralCCBase} ${
                    classes[
                      parenchymaClass(result?.ParenchymaCutsLateral, t)
                        ?.classColor
                    ]
                  }`
            }
            id={style.parenCC}
            onClick={() => {
              if (onClickLateralParen && result !== null && qualityInRevison)
                onClickLateralParen();
            }}
          />
        </Tooltip>

        {/* Parenchyma Medial */}
        <Tooltip
          onOpen={() =>
            qualityInfoMode &&
            showParencOverlays(result?.ParenchymaCutsMedial, 'Medial')
          }
          onClose={() =>
            qualityInfoMode && hideParencOverlays(view_position, 'Medial')
          }
          title={
            <Typography style={{ fontSize: '1.3vh', lineHeight: 1.3 }}>
              {t('ResultView.medial')}{' '}
              {parenchymaClass(result?.ParenchymaCutsMedial, t).text}
            </Typography>
          }
          arrow
          interactive
          placement={left ? 'right-start' : 'left-start'}
          TransitionComponent={Zoom}
        >
          <button
            style={{ cursor: qualityInRevison ? 'pointer' : 'help' }}
            className={
              qualityInDrawingRevision
                ? [classes.parenCCMedialBase, classes.neutral]
                : `${classes.parenCCMedialBase} ${
                    classes[
                      parenchymaClass(result?.ParenchymaCutsMedial, t)
                        ?.classColor
                    ]
                  }`
            }
            id={style.parenCC}
            onClick={() => {
              if (onClickMedialParen && result !== null && qualityInRevison)
                onClickMedialParen();
            }}
          />
        </Tooltip>

        {/* PNL */}
        <Tooltip
          arrow
          onOpen={() => qualityInfoMode && handlePnlMouseEnter()}
          onClose={() => qualityInfoMode && handlePnlMouseLeave()}
          title={
            <>
              <Typography
                style={{
                  fontSize: '1.3vh',
                  lineHeight: 1.3,
                }}
              >
                {
                  pnlTooltip(
                    result?.PNL,
                    view_position,
                    higherCCPNL,
                    pnlDifference,
                    t
                  ).text
                }
              </Typography>
              {pnlTooltip(
                result?.PNL,
                view_position,
                higherCCPNL,
                pnlDifference,
                t
              )?.textHelper && (
                <>
                  <Divider style={{ margin: '3% 0' }} />
                  <Typography
                    style={{
                      fontSize: '1.2vh',
                    }}
                  >
                    {
                      pnlTooltip(
                        result?.PNL,
                        view_position,
                        higherCCPNL,
                        pnlDifference,
                        t
                      )?.textHelper
                    }
                  </Typography>
                </>
              )}
            </>
          }
        >
          <svg
            style={{
              ...flip,
              top: nippleOrientationClass(
                result?.NippleCentered,
                result?.NippleAngle
              )?.pnlY,
            }}
            className={classes.pnlSvgCC}
          >
            <text
              x={left ? '40%' : '45%'}
              y="70%"
              fill="white"
              style={{
                fontSize: theme.fonts.responsiveMedium,
                cursor: 'help',
                fontWeight: pnlIsHovered ? 'bold' : 'normal',
                textShadow: pnlIsHovered ? '2px 2px 4px rgb(52 52 52)' : 'none',
              }}
            >
              {result && result.PectoNippleLength
                ? result.PectoNippleLength.toFixed(0)
                : null}
            </text>

            <line
              x1="0%"
              y1="90%"
              x2="100%"
              y2="90%"
              className={`${classes.buttonBase} ${classes.pnlBase} ${
                qualityInDrawingRevision
                  ? classes[pnlColor()?.color]
                  : classes[pnlColor(result)?.color]
              }`}
              // onClick={onClickPNL}
            />
          </svg>
        </Tooltip>
      </div>
    </div>
  );
};

export default QualityCC;
