import React, { useState, useEffect } from 'react';
import * as actions from '../../../../../store/index';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import io from 'socket.io-client';
import DatePicker from '../../../DrawerExamList/DatePicker/DatePicker';
import { API_ENDPOINT, AI_ENDPOINT } from '../../../../../config';
import { fetchDelete, fetchPut } from '../../../../../helpers/fetching';
import { formatDate } from '../../../../../helpers/formatDate';
import {
  setProcessingProgress,
  setActionToConfirm,
  setSnackbar,
  deleteExams,
} from '../../../../../store';
import {
  Switch,
  FormControlLabel,
  CircularProgress,
  Button,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  Slider,
} from '@material-ui/core';
import CustomDatePicker from '../../../DrawerExamList/DatePicker/CustomDatePicker';
import OfflinePinIcon from '@material-ui/icons/OfflinePin';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';

const useStyles = makeStyles((theme) => ({
  titleSettingsCard: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: '1.1rem',
  },

  spinner: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },

  progressLabel: {
    display: 'flex',
    justifyContent: 'center',
  },

  Slider: {
    width: '60%',
  },

  server_run_container: {
    display: 'flex',
    alignItems: 'center',
  },

  server_run_icon: {
    marginRight: '12px',
  },

  button: {
    minWidth: '8vw',
  },
}));

function Processing(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    currentlyProcessed,
    processingProgress,
    pendingReprocessing,
    exam_list,
  } = useSelector((state) => state.examinations);

  // Handlers

  function reprocessHandler() {
    dispatch(
      setActionToConfirm({
        action: reprocessStudies,
        text: t('Settings.confirm_reprocess'),
      })
    );
  }

  function deleteHandler() {
    dispatch(
      setActionToConfirm({
        action: deleteStudies,
        text: t('Settings.confirm_delete'),
      })
    );
  }

  // Fetching

  async function reprocessStudies() {
    const response = await fetchPut(
      'reprocess',
      {
        exam_ids: exam_list.map((exam) => exam.id),
      },
      'bbox'
    );

    if (response.success) {
      dispatch(
        setSnackbar({
          msg: t('Settings.reprocess_start'),
          severity: 'info',
        })
      );
    } else {
      dispatch(actions.setPendingReprocessing(false));
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
    }
  }

  async function deleteStudies() {
    dispatch(deleteExams(exam_list.map((exam) => exam.id)));
  }

  useEffect(() => {
    const newSocket = io(AI_ENDPOINT, {
      withCredentials: true,
      credentials: 'include',
    });

    newSocket.on('connect', () => {
      newSocket.on('reprocessed', (val) =>
        dispatch(actions.setProcessingProgress(val))
      );
    });

    newSocket.on('disconnect', () => {
      newSocket.off('reprocessed');
    });

    return () => newSocket.close();
  }, []);

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Card elevation={1}>
          <CardHeader
            disableTypography={true}
            classes={{
              action: classes.action,
            }}
            title={
              <Typography className={classes.titleSettingsCard} variant="h6">
                {t('Settings.dicom_receive')}
              </Typography>
            }
          />
          <CardContent>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              spacing={2}
            >
              <Grid item>
                {props.loadingOrthanc ? (
                  <CircularProgress className={classes.spinner} />
                ) : props.serverActive ? (
                  <div className={classes.server_run_container}>
                    <OfflinePinIcon
                      fontSize="small"
                      color="secondary"
                      className={classes.server_run_icon}
                    />
                    <Typography>{t('Settings.server')}</Typography>
                  </div>
                ) : (
                  <div className={classes.server_run_container}>
                    <RemoveCircleRoundedIcon
                      fontSize="small"
                      color="secondary"
                      className={classes.server_run_icon}
                    />
                    <Typography>{t('Settings.server_unavailable')}</Typography>
                  </div>
                )}
              </Grid>

              <Grid item>
                <FormControlLabel
                  control={
                    props.loadingWatcher ? (
                      <>
                        <CircularProgress className={classes.spinner} />
                      </>
                    ) : (
                      <Switch
                        checked={props.watcherActive}
                        onChange={props.setWatcher}
                      />
                    )
                  }
                  label={t('Settings.watcher')}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {localStorage.getItem('role') === 'admin' && (
        <>
          <Grid item>
            <Card elevation={1}>
              <CardHeader
                disableTypography={true}
                classes={{
                  action: classes.action,
                }}
                title={
                  <Typography
                    className={classes.titleSettingsCard}
                    variant="h6"
                  >
                    {t('Settings.reprocess_all')}
                  </Typography>
                }
                subheader={
                  <Typography variant="subtitle2">
                    {t('Settings.affected_studies')}
                  </Typography>
                }
              />
              <CardContent>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item style={{ flexGrow: 1 }}>
                    {pendingReprocessing || currentlyProcessed ? (
                      <>
                        <>
                          <CircularProgress className={classes.spinner} />
                        </>
                        {processingProgress[0] === 0 &&
                        processingProgress[1] === 0 ? (
                          'Processing'
                        ) : (
                          <p className={classes.progressLabel}>
                            {`${processingProgress[0]} / ${processingProgress[1]}`}
                          </p>
                        )}
                      </>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'space-around',
                          justifyContent: 'space-around',
                        }}
                      >
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="primary"
                          onClick={reprocessHandler}
                        >
                          {t('Settings.reprocess')}
                        </Button>
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="primary"
                          onClick={deleteHandler}
                        >
                          {t('Settings.delete')}
                        </Button>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default Processing;
