import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  tr_padding: {
    padding: '1px !important',
    width: '2%',
  },

  table_cell_container: {
    fontSize: theme.fonts.responsiveText,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '7vw !important',
    whiteSpace: 'nowrap',
    padding: '6px 2px !important',
  },

  table_cell_expand: {
    fontSize: theme.fonts.responsiveText,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '7vw !important',
    whiteSpace: 'nowrap',
    padding: '7px !important',
    background: '#3f5959',
  },

  table_font: {
    fontSize: theme.fonts.responsiveText,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    overflow: 'hidden',
  },

  table_date_font: {
    fontSize: theme.fonts.responsiveTextSmall,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    overflow: 'hidden',
    padding: '0 0 0 4px',
  },

  row_selected: {
    fontWeight: 'bold',
    fontSize: theme.fonts.responsiveText,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    overflow: 'hidden',
  },

  tr_hover: {
    '&:hover': {
      boxShadow:
        '0px -1px 2px 1px rgb(115 115 115), 0px 2px 2px 0px rgb(115 115 115)',
    },
  },
  main_nested_tr_container: {
    '&:hover': {
      boxShadow:
        '0px -1px 2px 1px rgb(115 115 115), 0px 2px 2px 0px rgb(115 115 115)',
    },
  },

  tr_nested: {
    fontSize: theme.fonts.responsiveText,
    padding: '6px 2px !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '7vw !important',
    borderBottom: '1px solid  rgb(71, 71, 71)',
  },

  tr_nested_hover: {
    cursor: 'pointer',
    borderBottom: '1px solid  rgb(71, 71, 71)',
    '&:hover': {
      boxShadow:
        '  0px 0px 3px 1px rgb(48 48 48 / 63%), 0px 1px 2px 0px rgb(58 58 58 / 69%)',
    },
  },

  tr_nested_hover_selected: {
    cursor: 'default',
    borderBottom: '1px solid  rgb(71, 71, 71)',
    '&:hover': {
      boxShadow:
        '  0px 0px 3px 1px rgb(48 48 48 / 63%), 0px 1px 2px 0px rgb(58 58 58 / 69%)',
    },
  },

  tr_padding_nested: {
    padding: '1px !important',
    width: '25px',
    borderBottom: '1px solid  rgb(71, 71, 71)',
  },

  info_icons_cell: {
    display: 'flex',
    flexDirection: 'column',
  },

  risk_icon_complete: {
    color: theme.palette.complementary.turquoise,
  },

  risk_icon_incomplete: {
    color: '#dac837',
  },

  table_placeholder: {
    color: 'gray',
    padding: '10%',
    border: 'none',
  },

  table_drag_placeholder: {
    padding: '6px',
    fontSize: '0.8rem',
    fontStyle: 'italic',
    color: 'gray',
    border: 'none',
  },

  drag_placeholder_icon: {
    color: 'gray',
  },

  dropzone_text: {
    color: theme.palette.complementary.turquoise,
    fontSize: theme.fonts.responsiveMediumBig,
  },

  previewChip_root: {
    maxWidth: theme.drawerWidthAvatar,
  },

  dropzone_root: {
    backgroundColor: 'inherit',
    minHeight: '70vh !important',
    padding: '1%',
    marginBottom: '10px',
    marginLeft: '1%',
    width: '99% !important',
  },

  dropzone_textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  dropzone_icon: {
    width: '25px !important',
    color: `${theme.palette.complementary.turquoise} !important`,
    height: 'auto !important',
    marginLeft: '16px !important',
  },

  table_actions_menu: {
    display: 'flex',
    alignItems: 'center',
  },

  menu_icon: {
    marginRight: '6px',
    height: '18px',
  },

  tooltip_texts: {
    letterSpacing: '0.1rem',
    fontSize: '10px',
  },
  tooltip_texts_margin: {
    letterSpacing: '0.1rem',
    fontSize: '12px',
    marginBottom: '4px',
  },

  drag_error: {
    fontSize: '12px',
    margin: '0px 20px',
    color: '#cfcfcf',
    letterSpacing: '0.1rem',
    fontStyle: 'italic',
  },

  drag_error_texts: {
    fontSize: '10px',
    margin: '2px 20px',
    color: '#cfcfcf',
    letterSpacing: '0.1rem',
    fontStyle: 'italic',
  },

  error_container: {
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },

  menu_text: {
    fontSize: theme.fonts.responsiveMedium,
  },

  hidden: {
    display: 'none',
  },
}));
