import { Chip, Divider, Tooltip, Typography } from '@material-ui/core';
import useResponsive from './hooks/useResponsive';
import { useQualityTranslation } from './hooks/useQualityTranslation';

export function qualityColor(currentClass, theme) {
  if (
    !currentClass ||
    currentClass === 'not_available' ||
    currentClass === 'Not available' ||
    typeof currentClass !== 'string' ||
    !currentClass.trim()
  ) {
    return 'gray';
  }

  const qualityClass = currentClass.trim().toLowerCase();

  const colorMap = {
    adequate: theme.palette.projections.adequate,
    inadequate: theme.palette.projections.inadequate,
    correct: theme.palette.projections.perfect,
    none: theme.palette.projections.perfect,
    perfect: theme.palette.projections.perfect,
    good: theme.palette.projections.good,
    moderate: theme.palette.projections.moderate,
    limited: theme.palette.projections.moderate,
    insufficient: theme.palette.projections.report_insufficient,
    severe: theme.palette.projections.report_insufficient,
    medium: theme.palette.lesions.skinFoldsMedium,
    mild: theme.palette.lesions.skinFoldsMild,
    straight: theme.palette.projections.perfect,
    convex: theme.palette.projections.report_insufficient,
    concave: theme.palette.projections.report_insufficient,
    no: theme.palette.texts.secondary,
    low: theme.palette.projections.report_insufficient,
    high: theme.palette.projections.report_insufficient,
  };

  return colorMap[qualityClass] || theme.palette.texts.secondary;
}

export const viewPositionText = (projection, t) => {
  if (projection === 'rcc') {
    return t('Dashboard.rcc').toUpperCase();
  }
  if (projection === 'lcc') {
    return t('Dashboard.lcc').toUpperCase();
  }
  if (projection === 'rmlo') {
    return t('Dashboard.rmlo').toUpperCase();
  }
  if (projection === 'lmlo') {
    return t('Dashboard.lmlo').toUpperCase();
  }
};

export const qualityEvaluationChip = (
  view_position,
  qualityResult,
  qualityExplanation,
  zoomedProjection,
  onPGMIClick,
  smallView,
  qualityInfoMode,
  qualityInRevison,
  theme,
  t
) => {
  const quality = useQualityTranslation(qualityResult, t);
  let qualityCapitalized = t('ResultView.missing_evaluated');

  if (!!qualityResult && qualityResult.length > 0)
    qualityCapitalized =
      quality[0].toUpperCase() + quality.slice(1, quality.length);

  return (
    <Tooltip
      arrow
      title={
        <>
          <Typography
            style={{
              letterSpacing: '0.1rem',
              fontSize: theme.fonts.responsiveMedium,
              fontWeight: 'bold',
            }}
          >
            {`${viewPositionText(view_position, t)} ${t(
              'ResultView.quality'
            )}: ${qualityCapitalized}`}
          </Typography>
          {qualityExplanation &&
            qualityExplanation.length > 0 &&
            !qualityInRevison && (
              <>
                <Divider style={{ margin: '3% 0' }} />
                {qualityExplanation.map((explanation, index) => (
                  <Typography
                    key={index}
                    style={{
                      letterSpacing: '0.1rem',
                      fontSize: theme.fonts.responsiveMedium,
                    }}
                  >
                    {`- ${explanation}`}
                  </Typography>
                ))}
              </>
            )}
        </>
      }
    >
      <Chip
        variant="default"
        onClick={() => {
          if (onPGMIClick && qualityResult !== null && qualityInRevison)
            onPGMIClick();
        }}
        label={
          <Typography
            style={{
              fontSize: '1.2vh',
              letterSpacing: '0.1rem',
              lineHeight: 'normal',
            }}
          >
            {quality?.toUpperCase() ?? t('ResultView.is_not_evaluated')}
          </Typography>
        }
        size="small"
        style={{
          zIndex: 2000,
          transform: zoomedProjection
            ? undefined
            : view_position === 'lcc' || view_position === 'lmlo'
            ? 'rotate(90deg)'
            : 'rotate(-90deg)',
          transformOrigin: 'center',
          background: qualityColor(qualityResult, theme),
          position: 'fixed',
          top: zoomedProjection
            ? '100%'
            : view_position === 'rmlo' || view_position === 'lmlo'
            ? '60%'
            : '50%',
          left: zoomedProjection
            ? view_position === 'lcc' || view_position === 'lmlo'
              ? '20%'
              : undefined
            : view_position === 'lcc' || view_position === 'lmlo'
            ? smallView
              ? '85%'
              : '90%'
            : undefined,
          right: zoomedProjection
            ? view_position === 'lcc' || view_position === 'lmlo'
              ? undefined
              : '20%'
            : view_position === 'lcc' || view_position === 'lmlo'
            ? undefined
            : smallView
            ? '85%'
            : '90%',
          height: '18px',
          minWidth: '130px',
          cursor: qualityInRevison ? 'pointer' : 'help',
        }}
      />
    </Tooltip>
  );
};
