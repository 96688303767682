import React from 'react';
import { Chip, Divider, Tooltip, Typography } from '@material-ui/core';
import symmetryVertical from '@iconify/icons-bi/symmetry-vertical';
import { Icon } from '@iconify/react';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import WavesIcon from '@material-ui/icons/Waves';
import { QUALITY_SYSTEM } from '../../../../../../config';
import { useQualityTranslation } from '../../../../../../helpers/hooks/useQualityTranslation';
import {
  COMPRESSION_HIGH,
  COMPRESSION_LOW,
  COMPRESSION_TOLERANCE,
} from '../../../../../../config';
import arrowCollapseVertical from '@iconify-icons/mdi/arrow-collapse-vertical';

export const parenchymaClass = (parenchymaResult, t) => {
  const resultMapping = {
    correct: {
      text: t('ResultView.quality_parenchyma_ok'),
      classColor: 'correct',
    },
    moderate: {
      text: t('ResultView.quality_parenc_not'),
      classColor: 'moderate',
    },
    insufficient: {
      text: t('ResultView.quality_parenc_not'),
      classColor: 'insufficient',
    },
  };

  const defaultResult = {
    text: t('ResultView.is_not_evaluated'),
    classColor: 'none',
  };

  return resultMapping[parenchymaResult] || defaultResult;
};

export const pnlTooltip = (PNL, view_position, higherPNL, pnlDifference, t) => {
  const currentStatePNL = higherPNL
    ? t('ResultView.more')
    : t('ResultView.less');
  const projPNL = view_position?.includes('cc')
    ? view_position === 'rcc'
      ? 'RMLO'
      : 'LMLO'
    : view_position === 'rmlo'
    ? 'RCC'
    : 'LCC';

  if (!PNL) {
    return { text: t('ResultView.is_not_evaluated'), classColor: 'none' };
  }

  const pnlMap = {
    correct: {
      text: t('ResultView.pnl_line_range_ok'),
    },
    moderate: {
      text: `${t('ResultView.pnl_line_range_moderate')}. `,
      textHelper: ` ${view_position?.toUpperCase()} ${t(
        'ResultView.pnl_measures'
      )} ${pnlDifference} ${currentStatePNL} ${t(
        'ResultView.than'
      )} ${projPNL}`,
    },
    insufficient: {
      text: `${t('ResultView.pnl_line_not_range')}. `,
      textHelper: `${view_position?.toUpperCase()} ${t(
        'ResultView.pnl_measures'
      )} ${pnlDifference} ${currentStatePNL} ${t(
        'ResultView.than'
      )} ${projPNL}`,
    },
  };

  const pnlResult = PNL ? pnlMap[PNL] : pnlMap.default;

  return (
    pnlResult || {
      text: t('ResultView.pnl_line_not_compare'),
      classColor: 'neutral',
    }
  );
};

export const pnlColor = (result) => {
  const { PNL } = result ?? {};
  if (!result) {
    return {
      color: 'neutral',
    };
  }
  const pnlMap = {
    correct: {
      color: 'correct',
    },
    moderate: {
      color: 'moderate',
    },
    insufficient: {
      color: 'insufficient',
    },
    default: {
      color: 'neutral',
    },
  };

  const pnlResult = PNL ? pnlMap[PNL] : pnlMap.default;

  return (
    pnlResult || {
      color: 'neutral',
    }
  );
};

export const nippleProfileClass = (nippleResult, t) => {
  if (!nippleResult || nippleResult === 'not_evaluated') {
    return {
      text: t('ResultView.is_not_evaluated'),
      classColor: 'none',
    };
  }

  const resultTextMap = {
    correct: t('ResultView.correctly'),
    moderate: t('ResultView.moderately'),
    insufficient: t('ResultView.insufficiently'),
  };

  const classColorMap = {
    correct: 'correct',
    moderate: 'moderate',
    insufficient: 'insufficient',
  };

  const resultText = resultTextMap[nippleResult] || '';
  const classColor = classColorMap[nippleResult] || classColorMap.none;

  return {
    text: `${t('ResultView.tooltip_nipple')} ${resultText}`,
    classColor: classColor,
  };
};

export const nippleOrientationClass = (nippleResult, nippleAngle) => {
  const defaultValues = {
    pnlY: '42%',
  };

  if (nippleResult === 'moderate' || nippleResult === 'insufficient') {
    const pnlY = nippleAngle > 0 ? '38%' : '46%';

    return { pnlY };
  }

  if (nippleResult === 'correct' || !nippleResult) {
    return defaultValues;
  }
};

export const pectoMuscClass = (pectoResult, t) => {
  const resultMapping = {
    correct: `${t('ResultView.tooltip_pect_musc')}: ${t(
      'ResultView.correctly'
    )}`,
    moderate: `${t('ResultView.tooltip_pect_musc')}: ${t(
      'ResultView.moderately'
    )}`,
    insufficient: `${t('ResultView.tooltip_pect_musc')}: ${t(
      'ResultView.insufficiently'
    )}`,
  };

  const defaultText = t('ResultView.is_not_evaluated');
  const defaultClassColor = 'none';

  return {
    text: resultMapping[pectoResult] || defaultText,
    classColor: pectoResult ? pectoResult : defaultClassColor,
  };
};

export const pectoAngleClass = (angleResult, angleValue, t) => {
  const pectAngleValue = angleValue ? `${angleValue.toFixed(1)}°` : '';
  const resultMapping = {
    correct: t('ResultView.correctly'),
    moderate: t('ResultView.moderately'),
    insufficient: t('ResultView.insufficiently'),
  };

  const defaultText = t('ResultView.is_not_evaluated');
  const defaultClassColor = 'none';

  const resultText = resultMapping[angleResult] || defaultText;
  const classColor = angleResult ? angleResult : defaultClassColor;

  if (!angleResult || !angleValue)
    return {
      text: defaultText,
      classColor: classColor,
    };
  else
    return {
      text: `${t(
        'ResultView.tooltip_pect_ang'
      )} ${resultText}: ${pectAngleValue}`,
      classColor: classColor,
    };
};

export const inframFoldClass = (inframFold, t) => {
  const resultMapping = {
    correct: t('ResultView.correctly'),
    moderate: t('ResultView.moderately'),
    insufficient: t('ResultView.insufficiently'),
  };

  const defaultText = t('ResultView.is_not_evaluated');
  const defaultClassColor = 'none';

  const resultText = resultMapping[inframFold] || defaultText;
  const classColor = inframFold ? inframFold : defaultClassColor;

  if (!inframFold)
    return {
      text: resultText,
      classColor: classColor,
    };
  return {
    text: `${t('ResultView.tooltip_infram')} ${resultText}`,
    classColor: classColor,
  };
};

const pectoLevelColor = (pectoLevel) => {
  if (!pectoLevel) return 'none';

  if (QUALITY_SYSTEM && QUALITY_SYSTEM === 'german') {
    return pectoLevel;
  } else {
    return pectoLevel === 'moderate' ? 'correct' : pectoLevel;
  }
};

export const pectoLevelClass = (pectoLevel, t) => {
  const resultMapping = {
    correct: `${t('ResultView.tooltip_pect_level')} ${t(
      'ResultView.correctly'
    )}`,
    moderate:
      QUALITY_SYSTEM === 'german'
        ? `${t('ResultView.tooltip_pect_level')} ${t(
            'ResultView.insufficiently'
          )}`
        : `${t('ResultView.tooltip_pect_level')} ${t('ResultView.correctly')}`,
    insufficient:
      QUALITY_SYSTEM === 'german'
        ? t('ResultView.tooltip_pect_level_insuff')
        : `${t('ResultView.tooltip_pect_level')} ${t(
            'ResultView.insufficiently'
          )}`,
  };

  const defaultText = t('ResultView.is_not_evaluated');
  const resultText = resultMapping[pectoLevel] || defaultText;
  const classColor = pectoLevelColor(pectoLevel);

  return {
    text: resultText,
    classColor: classColor,
  };
};

const symmetryColor = (symmetry, theme) => {
  if (symmetry === 'correct') return theme.palette.projections.symmetryCorrect;

  if (symmetry === 'moderate')
    return theme.palette.projections.symmetryModerate;

  if (symmetry === 'insufficient')
    return theme.palette.projections.symmetryInsufficient;

  if (symmetry === 'not_evaluated')
    return theme.palette.projections.not_evaluated;

  return theme.palette.projections.not_evaluated;
};

export const symmetryChip = (
  currentSymmetry,
  currentView,
  onClickSymmetryCC,
  onClickSymmetryMLO,
  zoomedProjection,
  classes,
  theme,
  t
) => {
  if (zoomedProjection || !currentSymmetry) return;

  return (
    <Tooltip
      placement="top"
      title={
        <Typography
          style={{
            fontSize: '1.3vh',
            lineHeight: 1.3,
          }}
        >
          {currentSymmetry === 'not_evaluated'
            ? t('ResultView.not_eval_feature')
            : `${currentView} ${t('ResultView.symmetry')}: ${currentSymmetry}`}
        </Typography>
      }
      arrow
    >
      <Chip
        label={
          currentSymmetry === 'not_evaluated'
            ? t('ResultView.not_eval_feature')
            : `${currentView} ${t('ResultView.symmetry')}`
        }
        size="small"
        icon={
          <Icon
            style={{
              width: '20px',
              height: 'auto',
              borderRadius: '5px',
              padding: '2px',
            }}
            color={symmetryColor(currentSymmetry, theme)}
            icon={symmetryVertical}
            rotate={4}
          />
        }
        variant="outlined"
        style={{
          height: '20px',
          fontSize: theme.fonts.responsiveMediumBig,
          lineHeight: 1.3,
          color: symmetryColor(currentSymmetry, theme),
          border: `1px solid ${symmetryColor(currentSymmetry, theme)}`,
          cursor: onClickSymmetryMLO || onClickSymmetryCC ? 'pointer' : 'help',
          zIndex: 900,
        }}
        onClick={() => {
          if (onClickSymmetryCC && currentSymmetry !== null)
            onClickSymmetryCC();
          if (onClickSymmetryMLO && currentSymmetry !== null)
            onClickSymmetryMLO();
        }}
      />
    </Tooltip>
  );
};

const findHigherClass = (class1, class2) => {
  const classOrder = [
    'adequate',
    'correct',
    'limited',
    'moderate',
    'insufficient',
    'inadequate',
  ];
  const class1Index = classOrder.indexOf(class1);
  const class2Index = classOrder.indexOf(class2);
  return class1Index > class2Index ? class1 : class2;
};

export const singleSymmetryChip = (
  evaluation,
  currentView,
  qualityInRevisionTab,
  onClickChip,
  zoomedProjection,
  classes,
  theme,
  t
) => {
  if (zoomedProjection || !evaluation) return;
  const currentProjections =
    currentView === 'cc' ? ['rcc', 'lcc'] : ['rmlo', 'lmlo'];
  const currentRSymmetry = evaluation?.[currentProjections?.[0]]?.symmetry;
  const currentLSymmetry = evaluation?.[currentProjections?.[1]]?.symmetry;

  const higherClass = findHigherClass(currentRSymmetry, currentLSymmetry);
  if (!currentRSymmetry && !currentLSymmetry) return;

  const handleClick = (proj) => {
    if (onClickChip && evaluation?.[proj]?.symmetry) {
      onClickChip(proj);
    }
  };

  return (
    <div
      style={{
        display: 'inline-flex',
        height: '20px',
        fontSize: theme.fonts.responsiveMediumBig,
        lineHeight: 1.3,

        cursor: qualityInRevisionTab ? 'pointer' : 'help',
        zIndex: 900,
        overflow: 'hidden',
      }}
    >
      {/* Right Side */}
      <Tooltip
        placement="top"
        title={
          <Typography
            style={{
              fontSize: '1.3vh',
              lineHeight: 1.3,
            }}
          >
            {currentRSymmetry === 'not_evaluated'
              ? t('ResultView.not_eval_feature')
              : `${currentProjections[0].toUpperCase()} ${t(
                  'ResultView.symmetry'
                )}: ${useQualityTranslation(currentRSymmetry, t)}`}
          </Typography>
        }
        arrow
      >
        <div
          style={{
            padding: '0 8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: symmetryColor(currentRSymmetry, theme),
            border: `1px solid ${symmetryColor(currentRSymmetry, theme)}`,
            borderRadius: '16px',
          }}
          onClick={() => handleClick(currentProjections[0])}
        >
          {currentRSymmetry === 'not_evaluated'
            ? t('ResultView.not_eval_feature')
            : useQualityTranslation(currentRSymmetry, t)}
        </div>
      </Tooltip>

      {/* Icon in the middle */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 4px',
        }}
      >
        <Icon
          icon={symmetryVertical}
          style={{ fontSize: '18px', color: symmetryColor(higherClass, theme) }}
        />
      </div>

      {/* Left Side */}
      <Tooltip
        placement="top"
        title={
          <Typography
            style={{
              fontSize: '1.3vh',
              lineHeight: 1.3,
            }}
          >
            {currentLSymmetry === 'not_evaluated'
              ? t('ResultView.not_eval_feature')
              : `${currentProjections[0].toUpperCase()} ${t(
                  'ResultView.symmetry'
                )}: ${useQualityTranslation(currentLSymmetry, t)}`}
          </Typography>
        }
        arrow
      >
        <div
          style={{
            padding: '0 8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: symmetryColor(currentLSymmetry, theme),
            border: `1px solid ${symmetryColor(currentLSymmetry, theme)}`,
            borderRadius: '16px',
          }}
          onClick={() => handleClick(currentProjections[1])}
        >
          {currentLSymmetry === 'not_evaluated'
            ? t('ResultView.not_eval_feature')
            : useQualityTranslation(currentLSymmetry, t)}
        </div>
      </Tooltip>
    </div>
  );
};

//SKIN FOLDS
const skinFoldChipColor = (foldsList, theme) => {
  const severityValues = {
    mild: 0,
    medium: 1,
    severe: 2,
  };

  if (foldsList?.length > 0) {
    const skinFoldsSeverityNumbers = foldsList.map(
      (fold) => severityValues[fold.severity]
    );
    const highestSeverity = Math.max(...skinFoldsSeverityNumbers);
    if (highestSeverity === 0) {
      return theme.palette.lesions.skinFoldsMild;
    }
    if (highestSeverity === 1) {
      return theme.palette.lesions.skinFoldsMedium;
    }
    if (highestSeverity === 2) {
      return theme.palette.lesions.skinFoldsSevere;
    }
    return theme.palette.lesions.skinFolds;
  }
  return theme.palette.lesions.noSkinFolds;
};

export const skinFoldsChip = (
  skinFolds,
  showSkinFolds,
  view_position,
  hidePectOverlays,
  notOnlyBoxes,
  zoomedProjection,
  t,
  theme
) => {
  return (
    <>
      <Tooltip
        placement="right-end"
        title={
          skinFolds ? (
            notOnlyBoxes ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  style={{
                    fontSize: '0.7rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '10px',
                  }}
                >
                  {`${view_position?.toUpperCase()}
                  ${t('ResultView.skin_folds')}`}
                </Typography>
                <Typography
                  style={{
                    fontSize: '0.7rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '10px',
                  }}
                >
                  <Brightness1Icon
                    fontSize="inherit"
                    style={{
                      color: theme.palette.lesions.skinFoldsSevere,
                      marginRight: '4px',
                    }}
                  />
                  {t('ResultView.skin_folds_severe')}
                </Typography>

                <Typography
                  style={{
                    fontSize: '0.7rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '10px',
                  }}
                >
                  <Brightness1Icon
                    fontSize="inherit"
                    style={{
                      color: theme.palette.lesions.skinFoldsMedium,
                      marginRight: '4px',
                    }}
                  />
                  {t('ResultView.skin_folds_medium')}
                </Typography>

                <Typography
                  style={{
                    fontSize: '0.7rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '10px',
                  }}
                >
                  <Brightness1Icon
                    fontSize="inherit"
                    style={{
                      color: theme.palette.lesions.skinFoldsMild,
                      marginRight: '4px',
                    }}
                  />
                  {t('ResultView.skin_folds_mild')}
                </Typography>
              </div>
            ) : (
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.3vh',
                }}
              >
                {t('ResultView.show_skin_folds')}
              </Typography>
            )
          ) : (
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: '1.3vh',
              }}
            >
              {t('ResultView.no_skin_folds')}
            </Typography>
          )
        }
        arrow
        interactive
      >
        <Chip
          variant="outlined"
          onMouseEnter={() => skinFolds && showSkinFolds(view_position)}
          onMouseLeave={() => skinFolds && hidePectOverlays()}
          label={
            skinFolds
              ? t('ResultView.skin_folds')
              : t('ResultView.none_skin_folds')
          }
          size="small"
          icon={
            <WavesIcon
              fontSize="small"
              style={{
                color: 'inherit',
                width: '18px',
              }}
            />
          }
          style={{
            color: skinFoldChipColor(skinFolds, theme),
            border: `1px solid ${skinFoldChipColor(skinFolds, theme)}`,
            height: '20px',
            fontSize: zoomedProjection
              ? theme.fonts.responsiveMedium
              : theme.fonts.responsiveMediumBig,
            marginTop: '6px',
            cursor: 'help',
          }}
        />
      </Tooltip>
    </>
  );
};

/* Compression */
const compressionColor = (compClass, theme) => {
  if (compClass === 'correct') return theme.palette.projections.compOptimal;
  if (compClass === 'moderate') return theme.palette.projections.compHigh;
  if (compClass === 'insufficient') return theme.palette.projections.compLow;
  return theme.palette.projections.not_evaluated;
};

const getCompressionChipData = (currentCompression, theme, t) => {
  const lowToleranceDown = COMPRESSION_LOW - COMPRESSION_TOLERANCE;
  const higherToleranceUp = COMPRESSION_HIGH + COMPRESSION_TOLERANCE;
  if (!currentCompression) {
    return (
      <Typography
        style={{
          letterSpacing: '0.1rem',
          textTransform: 'uppercase',
          fontSize: theme.fonts.responsiveMedium,
          fontWeight: 'bold',
        }}
      >
        {t('projections.no_compression')}
      </Typography>
    );
  }

  if (
    currentCompression >= COMPRESSION_LOW &&
    currentCompression <= COMPRESSION_HIGH
  )
    return (
      <>
        <Typography
          style={{
            letterSpacing: '0.1rem',
            textTransform: 'uppercase',
            fontSize: theme.fonts.responsiveMedium,
            fontWeight: 'bold',
          }}
        >
          {`${t('ResultView.cmp')}: ${currentCompression?.toFixed()} N`}
        </Typography>
        <Divider style={{ margin: '3% 0' }} />
        <Typography
          style={{
            letterSpacing: '0.1rem',
            fontSize: theme.fonts.responsiveMedium,
          }}
        >
          {t('ResultView.comp_ok', {
            compression_low: `${COMPRESSION_LOW}`,
            compression_high: `${COMPRESSION_HIGH}`,
          })}
        </Typography>
      </>
    );

  if (
    currentCompression >= lowToleranceDown &&
    currentCompression <= higherToleranceUp
  ) {
    if (currentCompression < COMPRESSION_LOW)
      return (
        <>
          <Typography
            style={{
              letterSpacing: '0.1rem',
              textTransform: 'uppercase',
              fontSize: theme.fonts.responsiveMedium,
              fontWeight: 'bold',
            }}
          >
            {`${t('ResultView.cmp')}: ${currentCompression?.toFixed()} N`}
          </Typography>
          <Divider style={{ margin: '3% 0' }} />
          <Typography
            style={{
              letterSpacing: '0.1rem',
              fontSize: theme.fonts.responsiveMedium,
            }}
          >
            {t('ResultView.comp_low', {
              compression: `${COMPRESSION_LOW} ${t(
                'ResultView.limits_tolerance'
              )} ${COMPRESSION_TOLERANCE}`,
            })}
          </Typography>
        </>
      );
    else if (currentCompression > COMPRESSION_HIGH)
      return (
        <>
          <Typography
            style={{
              letterSpacing: '0.1rem',
              textTransform: 'uppercase',
              fontSize: theme.fonts.responsiveMedium,
              fontWeight: 'bold',
            }}
          >
            {`${t('ResultView.cmp')}: ${currentCompression?.toFixed()} N`}
          </Typography>
          <Divider style={{ margin: '3% 0' }} />
          <Typography
            style={{
              letterSpacing: '0.1rem',
              fontSize: theme.fonts.responsiveMedium,
            }}
          >
            {t('ResultView.comp_high', {
              compression: `${COMPRESSION_HIGH}N ${t(
                'ResultView.limits_tolerance'
              )} ${COMPRESSION_TOLERANCE}`,
            })}
          </Typography>
        </>
      );
  }

  if (currentCompression < lowToleranceDown)
    return (
      <>
        <Typography
          style={{
            letterSpacing: '0.1rem',
            textTransform: 'uppercase',
            fontSize: theme.fonts.responsiveMedium,
            fontWeight: 'bold',
          }}
        >
          {`${t('ResultView.cmp')}: ${currentCompression?.toFixed()} N`}
        </Typography>
        <Divider style={{ margin: '3% 0' }} />
        <Typography
          style={{
            letterSpacing: '0.1rem',
            fontSize: theme.fonts.responsiveMedium,
          }}
        >
          {t('ResultView.comp_low', {
            compression: `${COMPRESSION_LOW} and below the tolerance range of ${COMPRESSION_TOLERANCE}`,
          })}
        </Typography>
      </>
    );

  if (currentCompression > higherToleranceUp)
    return (
      <>
        <Typography
          style={{
            letterSpacing: '0.1rem',
            textTransform: 'uppercase',
            fontSize: theme.fonts.responsiveMedium,
            fontWeight: 'bold',
          }}
        >
          {`${t('ResultView.cmp')}: ${currentCompression?.toFixed()} N`}
        </Typography>
        <Divider style={{ margin: '3% 0' }} />

        <Typography
          style={{
            letterSpacing: '0.1rem',
            fontSize: theme.fonts.responsiveMedium,
          }}
        >
          {t('ResultView.comp_high', {
            compression: `${COMPRESSION_HIGH}N ${t(
              'ResultView.above_tolerance'
            )} ${COMPRESSION_TOLERANCE}`,
          })}
        </Typography>
      </>
    );

  if (
    currentCompression > COMPRESSION_HIGH &&
    currentCompression <= higherToleranceUp
  )
    return (
      <>
        <Typography
          style={{
            letterSpacing: '0.1rem',
            textTransform: 'uppercase',
            fontSize: theme.fonts.responsiveMedium,
            fontWeight: 'bold',
          }}
        >
          {`${t('ResultView.cmp')}: ${currentCompression?.toFixed()} N`}
        </Typography>
        <Divider style={{ margin: '3% 0' }} />

        <Typography
          style={{
            letterSpacing: '0.1rem',
            fontSize: theme.fonts.responsiveMedium,
          }}
        >
          {t('ResultView.comp_high', {
            compression: `${COMPRESSION_HIGH}N ${t(
              'ResultView.limits_tolerance'
            )} ${COMPRESSION_TOLERANCE}`,
          })}
        </Typography>
      </>
    );
};

export const compressionChip = (
  compression,
  compressionClass,
  onClickCompression,
  qualityInRevison,
  zoomedProjection,
  theme,
  t
) => {
  return (
    <Tooltip
      arrow
      title={
        getCompressionChipData(compression, theme, t) ?? (
          <Typography
            style={{
              letterSpacing: '0.1rem',
              textTransform: 'uppercase',
              fontSize: theme.fonts.responsiveMedium,
              fontWeight: 'bold',
            }}
          >
            {t('projections.no_compression')}
          </Typography>
        )
      }
      placement="right-start"
    >
      <Chip
        label={useQualityTranslation(compressionClass, t)}
        size="small"
        onClick={() => qualityInRevison && onClickCompression()}
        icon={
          <Icon
            icon={arrowCollapseVertical}
            fontSize="small"
            color={compressionColor(compressionClass, theme)}
          />
        }
        variant="outlined"
        style={{
          color: compressionColor(compressionClass, theme),
          border: `1px solid ${compressionColor(compressionClass, theme)}`,
          cursor: qualityInRevison ? 'pointer' : 'help',
          height: '20px',
          fontSize: zoomedProjection
            ? theme.fonts.responsiveMedium
            : theme.fonts.responsiveMediumBig,
        }}
      />
    </Tooltip>
  );
};

//Blur
const blurredAreaInfo = (blur, theme, t) => {
  let color = '';
  let label = '';
  let tooltip = '';

  if (blur === 'correct') {
    color = theme.palette.projections.blur_ok;
    label = t('ResultView.correct_blurred_area');
    tooltip = t('ResultView.no_blurred_area');
  } else if (blur === 'insufficient') {
    color = theme.palette.projections.blur_insufficient;
    label = t('ResultView.blurred_area');
    tooltip = t('ResultView.has_blurred_area');
  } else if (!blur || blur === 'not_evaluated') {
    color = 'gray';
    label = t('ResultView.blur_not_evaluated');
    tooltip = t('ResultView.not_eval_blurred_area');
  }

  return {
    color,
    label,
    tooltip,
  };
};

export const blurAreaChip = (
  blurredAreas,
  onClickBlur,
  qualityInfoMode,
  qualityInRevison,
  zoomedProjection,
  theme,
  t
) => {
  return (
    <>
      <Tooltip
        arrow
        title={
          <Typography
            style={{
              letterSpacing: '0.1rem',
              fontSize: theme.fonts.responsiveMedium,
            }}
          >
            {blurredAreaInfo(blurredAreas, theme, t)?.tooltip}
          </Typography>
        }
      >
        <Chip
          variant="outlined"
          onClick={() => {
            onClickBlur && qualityInRevison && onClickBlur();
          }}
          label={
            blurredAreas
              ? blurredAreaInfo(blurredAreas, theme, t)?.label
              : t('ResultView.blur_not_evaluated')
          }
          size="small"
          icon={
            <BlurCircularIcon
              fontSize="small"
              style={{
                color: 'inherit',
                width: '18px',
              }}
            />
          }
          style={{
            color: blurredAreaInfo(blurredAreas, theme, t)?.color,
            border: `1px solid ${
              blurredAreaInfo(blurredAreas, theme, t)?.color
            }`,
            height: '20px',
            fontSize: zoomedProjection
              ? theme.fonts.responsiveMedium
              : theme.fonts.responsiveMediumBig,
            marginTop: '6px',
            cursor: qualityInRevison ? 'pointer' : 'help',
          }}
        />
      </Tooltip>
    </>
  );
};
