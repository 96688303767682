import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DraggableDialog from '../DraggableDialog/DraggableDialog';
import { Divider, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

function VersionChangeLogs(props) {
  const { t } = useTranslation();
  const [changelogData, setChangelogData] = useState(null);
  const [error, setError] = useState('');
  const softwareVersion = process.env.REACT_APP_SOFTWARE_VERSION;

  const fetchChangelogData = async () => {
    try {
      const response = await fetch('/static/release/release.json');

      if (!response.ok) {
        const errorMessage = `${t('Layout.no_changelog')} ${response.status}`;
        console.log(errorMessage);
        setError(
          `${t('Layout.none_changelog')}${
            softwareVersion ? ` - Software Version: ${softwareVersion}` : ''
          }`
        );
        throw new Error(
          `${t('Layout.failed_changelog')}. (${
            response.status
          }): ${errorMessage}`
        );
      }
      const data = await response.json();
      if (!data || !data[props.lang]) {
        throw new Error(`${props.lang} - ${t('Layout.no_changelog')}.`);
      }

      setChangelogData(data[props.lang]);
    } catch (error) {
      if (error instanceof SyntaxError) {
        console.log(`${t('Layout.error_changelog')} ${error.message}`);
        setError(
          `${t('Layout.none_changelog')}${
            softwareVersion ? ` - Software Version: ${softwareVersion}` : ''
          }`
        );
      } else {
        console.log(`${t('Layout.error_changelog')} ${error.message}`);
        setError(
          `${t('Layout.none_changelog')}${
            softwareVersion ? ` - Software Version: ${softwareVersion}` : ''
          }`
        );
      }
    }
  };

  useEffect(() => {
    fetchChangelogData();
  }, [props.lang]);

  return (
    <DraggableDialog
      maxWidth="sm"
      title={props.title}
      open={props.open}
      actions={[
        { onClick: props.onClose, color: 'primary', label: t('Console.close') },
      ]}
      onClose={props.onClose}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {changelogData ? (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                  }}
                >
                  {changelogData?.version}
                </Typography>
                <Typography>{changelogData?.date?.slice(0, 10)}</Typography>
              </div>
            </div>
            {changelogData?.notes && changelogData?.notes?.length > 0 ? (
              changelogData.notes?.map((section) => (
                <div key={section.title} style={{ marginBottom: '10px' }}>
                  <Typography
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {section?.title}
                  </Typography>
                  <Divider />

                  <div
                    style={{
                      margin: '6px 16px',
                    }}
                  >
                    {section?.items?.map((item, index) => (
                      <Typography key={index}>- {item}</Typography>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <Typography>{t('Layout.none_changelog')}</Typography>
            )}
          </>
        ) : error ? (
          <div>{error}</div>
        ) : (
          <div>{t('Layout.loading_changelog')}</div>
        )}
      </div>
    </DraggableDialog>
  );
}

export default VersionChangeLogs;
