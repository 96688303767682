import React, { useState } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  useTheme,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useStyles } from '../ExaminationTable.style';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import SyncIcon from '@material-ui/icons/Sync';
import PersonAddDisabled from '@material-ui/icons/PersonAddDisabled';
import { useTranslation } from 'react-i18next';
import * as actions from '../../../../../../store/index';
import { fetchPut } from '../../../../../../helpers/fetching';
import {
  setActionToConfirm,
  setSnackbar,
  setCurrentlyProcessed,
  deleteExams,
  deletePatient,
  fetchResults,
} from '../../../../../../store';

function ExaminationTableActions({ exam_id, isNestedExam, nestedRowTitle }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const { currentlyProcessed, pendingReprocessing, currentExam, fetchingExamList } = useSelector(
    (state) => state.examinations
  );
  const [uploadingProgress, setUploadingProgress] = useState(100);
  const selected = currentExam === exam_id;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handledeleteExams = (current_id) => {
    setAnchorEl(null);
    dispatch(
      setActionToConfirm({
        action: () => dispatch(deleteExams([current_id])),
        text: t('Confirm.delete_exam'),
      })
    );
  };

  const handleDeletePatient = (current_id) => {
    setAnchorEl(null);
    dispatch(
      setActionToConfirm({
        action: () => dispatch(deletePatient(current_id)),
        text: t('Confirm.patient_delete') + '?',
      })
    );
  };

  const handleRefreshExam = async (current_id) => {
    setUploadingProgress(5);
    const fakeProgress = setInterval(() => {
      setUploadingProgress((prevState) => Math.min(prevState + 4, 89));
    }, 1300);
    dispatch(setCurrentlyProcessed(current_id));
    setAnchorEl(null);
    const response = await fetchPut('reprocess', { exam_ids: [current_id] }, 'bbox');
    if (response.success) {
      dispatch(setCurrentlyProcessed(null));
      setUploadingProgress(99);
      dispatch(
        setSnackbar({
          msg: t('Table.reprocessing_begun'),
          severity: 'success',
        })
      );
    } else {
      dispatch(setCurrentlyProcessed(null));
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
    }
    clearInterval(fakeProgress);
  };

  return (
    <TableCell className={classes.tr_padding} align='left' key='actions'>
      {exam_id === currentlyProcessed ? (
        <IconButton size='small'>
          <CircularProgress
            variant={uploadingProgress === 100 ? 'indeterminate' : 'determinate'}
            value={uploadingProgress}
            style={{
              maxWidth: '20px',
              height: '20px',
              marginRight: '2px',
            }}
            color='secondary'
          />
        </IconButton>
      ) : (
        <>
          {pendingReprocessing || fetchingExamList || currentlyProcessed !== null ? (
            <IconButton style={{ color: '#a1a1a1' }} size='small' disabled>
              <MoreVertIcon fontSize='small' />
            </IconButton>
          ) : (
            <>
              <IconButton
                style={{ color: theme.palette.complementary.turquoise }}
                size='small'
                onClick={handleMenuClick}
              >
                <MoreVertIcon fontSize='small' />
              </IconButton>
              <Menu
                id='menu-popular-card'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                variant='selectedMenu'
              >
                {isNestedExam ? (
                  <MenuItem
                    onClick={() => handledeleteExams(exam_id)}
                    className={classes.table_actions_menu}
                  >
                    <DeleteIcon fontSize='small' className={classes.menu_icon} />
                    <Typography className={classes.menu_text}> {t('Confirm.delete')} </Typography>
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={() => handleDeletePatient(exam_id)}
                    className={classes.table_actions_menu}
                  >
                    <PersonAddDisabled fontSize='small' className={classes.menu_icon} />
                    <Typography className={classes.menu_text}>
                      {t('Confirm.patient_delete')}
                    </Typography>
                  </MenuItem>
                )}
                {((nestedRowTitle && isNestedExam) || !nestedRowTitle) && (
                  <MenuItem
                    onClick={() => handleRefreshExam(exam_id)}
                    className={classes.table_actions_menu}
                  >
                    <SyncIcon fontSize='small' className={classes.menu_icon} />
                    <Typography className={classes.menu_text}> {t('Confirm.refresh')} </Typography>
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
        </>
      )}
    </TableCell>
  );
}

export default ExaminationTableActions;
