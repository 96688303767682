import React from 'react';
import { useHistory } from 'react-router-dom';
import { fetchPost } from '../../helpers/fetching';
import { useDispatch } from 'react-redux';

import { resetDatesFilters, resetFilter, setInitialExamState,
         setFilterProperty, setInitialLabeling, setSnackbar } from '../../store';
import { formatDate } from '../../helpers/formatDate';

const Logout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  dispatch(setInitialExamState());
  dispatch(resetDatesFilters());
  dispatch(setFilterProperty(['dates', [
    formatDate(new Date(), '00:00'),
    formatDate(new Date(), '23:59'),
  ]]))
  dispatch(resetFilter());
  dispatch(setInitialLabeling());
  dispatch(setSnackbar(null));

  localStorage.clear()
  console.log('Adios!');

  fetchPost('logout');
  
  const logoutTimeout = setTimeout(() => {
    history.push('/login');
  }, 100);

  return null;
};

export default Logout;
